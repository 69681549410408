<template lang="pug">
ul.FcwChecklist(:class="classes")
  li.FcwChecklist__item(
    v-for="(item, index) in items"
    :key="index"
  )
    FIcon(:name="icon")
    span {{ item }}
</template>

<style lang="stylus">
.FcwChecklist
  display flex
  flex-direction column
  margin 0
  padding 0
  gap rem(16)

  &--dense
    gap rem(8)

.FcwChecklist__item
  display flex
  align-items flex-start
  gap rem(16)
</style>

<script setup lang="ts">
import type { Icon } from '@fifteen/design-system-vue';

export interface FcwChecklistProps {
  /**
   * Array of strings
   */
  items: string[];
  /**
   * Icon name of item list
   */
  icon?: Icon;
  /**
   * Reduce space between list items
   */
  dense?: boolean;
}

const props = withDefaults(defineProps<FcwChecklistProps>(), {
  icon: 'checkCircle',
  dense: false,
});

const classes = computed<VueClasses>(() => ({
  'FcwChecklist--dense': props.dense,
}));
</script>
